'use client'

import {
  Modal,
  modalStyles,
  overlayStyles,
} from '@paladise/ui/components/Modal'
import { Dialog } from '@paladise/ui/components/Dialog'
import { Heading } from 'lib/react-aria-components'
import { Button } from '@paladise/ui/components/Button'
import { useTranslations } from 'next-intl'
import { ComingSoonDialogEnum } from '../type'

interface IComingSoonDialogProps {
  isOpen: boolean
  type?: ComingSoonDialogEnum
  onClose: () => void
}

const ComingSoonDialog = ({
  isOpen,
  type,
  onClose,
}: IComingSoonDialogProps) => {
  const t = useTranslations()
  return (
    <Modal
      modalClassName={modalStyles}
      overlayClassName={overlayStyles}
      isOpen={isOpen}
    >
      <Dialog
        role="alertdialog"
        className="w-[343px] rounded-[20px] bg-white p-4"
      >
        <>
          <Heading
            slot="title"
            className="text-title pt-2 text-center text-2xl"
          >
            Coming Soon!
          </Heading>
          <p className="text-label-l2 text-body my-2 text-center">
            We will launch an {type || '-'} version soon, so stay tuned
          </p>
          <div className="mt-6 flex flex-col gap-2 text-[15px] font-semibold">
            <Button variant="primary" className="p-[15px]" onPress={onClose}>
              {t('ok')}
            </Button>
          </div>
        </>
      </Dialog>
    </Modal>
  )
}

export default ComingSoonDialog
