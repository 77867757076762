'use client'

import { Dialog as RACDialog, type DialogProps } from 'react-aria-components'
import { twMerge } from 'tailwind-merge'
import { tv } from 'tailwind-variants'

export const dialogStyles = tv({
  base: 'w-full rounded-[20px] px-4 pb-4 pt-6',
})

export function Dialog(props: DialogProps) {
  return (
    <RACDialog
      {...props}
      className={twMerge(
        'relative max-h-[inherit] overflow-auto outline outline-0 [[data-placement]>&]:p-4',
        props.className,
      )}
    />
  )
}
