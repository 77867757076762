'use client'

import { CDN_URL } from '@paladise/config/constants'
import { Dialog } from '@paladise/ui/components/Dialog'
import {
  Modal,
  modalStyles,
  overlayStyles,
} from '@paladise/ui/components/Modal'
import { Heading } from 'lib/react-aria-components'
import { useTranslations } from 'next-intl'
import React from 'react'
import { useDevice } from 'store/provider/device-provider'

const context = React.createContext<{
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}>({
  isOpen: false,
  setIsOpen: () => {},
})

export const useQrcodeModal = () => React.useContext(context)

export const QrcodeModalProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  return (
    <context.Provider
      value={{
        isOpen,
        setIsOpen,
      }}
    >
      {children}
    </context.Provider>
  )
}

const QrcodeModal = () => {
  const { isOpen, setIsOpen } = useQrcodeModal()
  const t = useTranslations()
  const device = useDevice()
  return (
    <Modal
      modalClassName={modalStyles({
        className: 'rounded-none bg-transparent',
      })}
      overlayClassName={overlayStyles({ className: 'bg-overlay-still' })}
      isOpen={isOpen && device === 'desktop'}
      onOpenChange={setIsOpen}
      isDismissable
      isKeyboardDismissDisabled
    >
      <Dialog>
        <div className="flex flex-col items-center gap-6">
          <img
            src={CDN_URL + '/human/images/qr_code@2x.png'}
            alt="QRCode"
            className="size-[280px]"
            width="96"
            height="96"
          />
          <Heading
            slot="title"
            className="text-center text-lg font-semibold text-[--color-palup-brand-yellow]"
          >
            {t('common.download_the_app_and_start_interacting!')}
          </Heading>
        </div>
      </Dialog>
    </Modal>
  )
}

export default QrcodeModal
