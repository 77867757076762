'use client'

import {
  ModalOverlay,
  Modal as RACModal,
  type ModalOverlayProps,
} from 'react-aria-components'
import { tv } from 'tailwind-variants'

export function Modal({
  overlayClassName,
  modalClassName,
  ...props
}: Omit<ModalOverlayProps, 'className'> & {
  overlayClassName?: ModalOverlayProps['className']
  modalClassName?: ModalOverlayProps['className']
}) {
  return (
    <ModalOverlay {...props} className={overlayClassName}>
      <RACModal {...props} className={modalClassName} />
    </ModalOverlay>
  )
}

export const overlayStyles = tv({
  base: 'bg-overlay-light fixed left-0 top-0 isolate z-20 flex h-[--visual-viewport-height] w-full items-center justify-center p-[16px] text-center',
  variants: {
    isEntering: {
      true: 'animate-in fade-in duration-200 ease-out',
    },
    isExiting: {
      true: 'animate-out fade-out duration-200 ease-in',
    },
  },
})

export const modalStyles = tv({
  base: 'max-h-full w-auto rounded-[20px] bg-white bg-clip-padding text-left align-middle forced-colors:bg-[Canvas]',
  variants: {
    isEntering: {
      true: 'animate-in zoom-in-105 duration-200 ease-out',
    },
    isExiting: {
      true: 'animate-out zoom-out-95 duration-200 ease-in',
    },
  },
})

export const fullPageOverlayStyles = tv({
  base: 'z-modal bg-overlay-light fixed left-0 top-0 isolate flex h-[--visual-viewport-height] w-full items-center justify-center',
  variants: {
    isEntering: {
      true: 'animate-in fade-in duration-200 ease-out',
    },
    isExiting: {
      true: 'animate-out fade-out duration-200 ease-in',
    },
  },
})

export const fullPageModalStyles = tv({
  base: 'h-full w-full bg-white text-left align-middle forced-colors:bg-[Canvas]',
  variants: {
    isEntering: {
      true: 'animate-in zoom-in-105 duration-200 ease-out',
    },
    isExiting: {
      true: 'animate-out zoom-out-95 duration-200 ease-in',
    },
  },
})

export const mobileFullPageModalStyles = tv({
  extend: fullPageModalStyles,
  base: 'min720:h-auto min720:w-auto min720:rounded-[16px] isolate overflow-hidden',
})

export const mobileFullPageOverlayStyles = tv({
  extend: fullPageOverlayStyles,
  base: 'bg-overlay-thick',
})
