'use client'

import ComingSoonDialog from 'features/about/components/ComingSoonDialog'
import { ComingSoonDialogEnum } from 'features/about/type'
import React from 'react'

type State = {
  isOpen: boolean
  type: ComingSoonDialogEnum
}

const context = React.createContext<{
  state: State
  setState: React.Dispatch<React.SetStateAction<State>>
}>({
  state: {
    isOpen: false,
    type: ComingSoonDialogEnum.iOS,
  },
  setState: () => {},
})

export const useComingSoon = () => React.useContext(context)

export const ComingSoonProvider = ({
  defaultIsOpen = false,
  children,
}: {
  defaultIsOpen?: boolean
  children: React.ReactNode
}) => {
  const [state, setState] = React.useState({
    isOpen: defaultIsOpen,
    type: ComingSoonDialogEnum.iOS,
  })

  return (
    <context.Provider
      value={{
        state,
        setState,
      }}
    >
      {children}
    </context.Provider>
  )
}

const ComingSoonModal = () => {
  const { state, setState } = useComingSoon()
  return (
    <>
      <ComingSoonDialog
        type={state.type}
        isOpen={state.isOpen}
        onClose={() => setState(prev => ({ ...prev, isOpen: false }))}
      />
    </>
  )
}

export default ComingSoonModal
