'use client'
import { useQrcodeModal } from 'features/home/components/QrcodeModal'
import React from 'react'

const QrcodeButton = ({ children }: { children: React.ReactElement }) => {
  const { setIsOpen } = useQrcodeModal()

  const Children = React.cloneElement(children, {
    onPress: () => setIsOpen(true),
  })

  return <>{Children}</>
}

export default QrcodeButton
