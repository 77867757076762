import { CDN_URL } from '@paladise/config/constants'

export const MOBILE_VIDEO_POSTER = CDN_URL + '/landing-page/landing_mobile.jpg'
export const DESKTOP_VIDEO_POSTER =
  CDN_URL + '/landing-page/landing_desktop.jpg'

export const VIDEOS = {
  cn: {
    MOBILE: {
      MANIFEST:
        CDN_URL +
        '/landing-page/landing-mobile-h264/h264_landing_mobile_cn.m3u8',
      MP4_MANIFEST: CDN_URL + '/landing-page/landing_mobile_cn_s.mp4',
    },
    DESKTOP: {
      MANIFEST:
        CDN_URL +
        '/landing-page/landing-desktop-h264/h264_landing_desktop_cn.m3u8',
      MP4_MANIFEST: CDN_URL + '/landing-page/landing_desktop_cn_s.mp4',
    },
  },
  en: {
    MOBILE: {
      MANIFEST:
        CDN_URL + '/landing-page/landing-mobile-h264/h264_landing_mobile.m3u8',
      MP4_MANIFEST: CDN_URL + '/landing-page/landing_mobile_s.mp4',
    },
    DESKTOP: {
      MANIFEST:
        CDN_URL +
        '/landing-page/landing-desktop-h264/h264_landing_desktop.m3u8',
      MP4_MANIFEST: CDN_URL + '/landing-page/landing_desktop_s.mp4',
    },
  },
  es: {
    MOBILE: {
      MANIFEST:
        CDN_URL +
        '/landing-page/landing-mobile-h264/h264_landing_mobile_es.m3u8',
      MP4_MANIFEST: CDN_URL + '/landing-page/landing_mobile_es_s.mp4',
    },
    DESKTOP: {
      MANIFEST:
        CDN_URL +
        '/landing-page/landing-desktop-h264/h264_landing_desktop_es.m3u8',
      MP4_MANIFEST: CDN_URL + '/landing-page/landing_desktop_es_s.mp4',
    },
  },
  fr: {
    MOBILE: {
      MANIFEST:
        CDN_URL +
        '/landing-page/landing-mobile-h264/h264_landing_mobile_fr.m3u8',
      MP4_MANIFEST: CDN_URL + '/landing-page/landing_mobile_fr_s.mp4',
    },
    DESKTOP: {
      MANIFEST:
        CDN_URL +
        '/landing-page/landing-desktop-h264/h264_landing_desktop_fr.m3u8',
      MP4_MANIFEST: CDN_URL + '/landing-page/landing_desktop_fr_s.mp4',
    },
  },
  jp: {
    MOBILE: {
      MANIFEST:
        CDN_URL +
        '/landing-page/landing-mobile-h264/h264_landing_mobile_jp.m3u8',
      MP4_MANIFEST: CDN_URL + '/landing-page/landing_mobile_jp_s.mp4',
    },
    DESKTOP: {
      MANIFEST:
        CDN_URL +
        '/landing-page/landing-desktop-h264/h264_landing_desktop_jp.m3u8',
      MP4_MANIFEST: CDN_URL + '/landing-page/landing_desktop_jp_s.mp4',
    },
  },
  kr: {
    MOBILE: {
      MANIFEST:
        CDN_URL +
        '/landing-page/landing-mobile-h264/h264_landing_mobile_kr.m3u8',
      MP4_MANIFEST: CDN_URL + '/landing-page/landing_mobile_kr_s.mp4',
    },
    DESKTOP: {
      MANIFEST:
        CDN_URL +
        '/landing-page/landing-desktop-h264/h264_landing_desktop_kr.m3u8',
      MP4_MANIFEST: CDN_URL + '/landing-page/landing_desktop_kr_s.mp4',
    },
  },
} as const

export const LOCALE_VIDEO_MAP = {
  'zh-CN': 'cn',
  'zh-TW': 'cn',
  ja: 'jp',
  ko: 'kr',
  en: 'en',
  es: 'es',
  fr: 'fr',
} as const
