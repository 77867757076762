'use client'

import { ComingSoonDialogEnum } from 'features/about/type'
import React from 'react'
import { useComingSoon } from './ComingSoonModal'

const ComingSoonButton = ({
  active,
  type,
  children,
}: {
  active?: boolean
  type: ComingSoonDialogEnum
  children: React.ReactElement
}) => {
  const { setState } = useComingSoon()

  const onChildrenClick = (e: any) => {
    if (active) {
      return
    }
    e.preventDefault()
    setState({
      isOpen: true,
      type,
    })
  }

  const Children = React.cloneElement(children, {
    onClick: onChildrenClick,
  })

  return <>{Children}</>
}

export default ComingSoonButton
