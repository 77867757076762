import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/consumer/src/components/HamburgerMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/consumer/src/components/language-selector/language-selector.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/consumer/src/features/home/components/ComingSoonButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ComingSoonProvider","default"] */ "/app/apps/consumer/src/features/home/components/ComingSoonModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/consumer/src/features/home/components/QrcodeButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["QrcodeModalProvider","default"] */ "/app/apps/consumer/src/features/home/components/QrcodeModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MutedButton","default"] */ "/app/apps/consumer/src/features/home/components/Video.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["Button"] */ "/app/packages/ui/src/components/Button.tsx");
